<template>
  <v-layout align-center>
    <v-select
      current
      v-model="lang"
      :items="langs"
      :label="$t('Language')"
      hide-details="auto"
      class="pageElementLanguageSelect mr-4 mt-0"
      single-line
      outlined
      dense
    ></v-select>
    <div :class="isRepeating ? 'full-width ' : 'full-width mr-sm-4'">
      <v-text-field
        v-if="!itemData.TranslatedTitle"
        :label="$t('Object title')"
        v-model="itemData.Title"
        class="full-width"
        outlined
        dense
        hide-details="auto"
        single-line
      ></v-text-field>

      <v-text-field
        v-else
        :label="$t('Object title')"
        v-model="itemData.TranslatedTitle[lang]"
        class="full-width"
        outlined
        dense
        hide-details="auto"
        single-line
      ></v-text-field>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: ["itemData", "isRepeating"],
  data() {
    return {
      langs: this.$config.languages,
      lang: "Nl",
    };
  },
  created() {
    if (!this.itemData.TranslatedTitle) {
      this.itemData["TranslatedTitle"] = {};
      this.itemData["TranslatedTitle"][this.lang] = this.itemData.Title;
    }
  },

  watch: {
    lang: function (param) {
      this.$emit("changeLanguage", param);
    },
    "itemData.TranslatedTitle": function (param) {
      this.$emit("changeTranslatedTitle", param);
    },
  },
};
</script>

<style>
</style>